import { CarrelloInfoProdotto } from "./infoProdotto";
import { parametriDichiarazioni } from "./parametriDichiarazioni";
import { parametriPreventivatoreItem } from "./parametriPreventivatore";
import { PartecipantiCarrello } from "./partecipantiCarrello";

export class datiViaggio {
	itemID: number | null;
	pacchettoID: number | null;

	parametri: parametriPreventivatoreItem | null;
	
	note: string;
	riferimentoPratica: string;

	partecipanti: PartecipantiCarrello[] | null;
	immaginePartecipanti: string;
	tesseraSingola: boolean | null;
	parametriDichiarazione: parametriDichiarazioni | null;
	prodottiCollegati: any[];
	totali: totaliCarrello;
	complementari: CarrelloInfoProdotto[] | null;
	ulterioriDati: any[];
	inModifica: boolean | false;



	constructor(){
		this.itemID = 0;
		this.pacchettoID = null;
		this.parametri = new parametriPreventivatoreItem();
		this.note = null;
		this.riferimentoPratica = null;
		this.partecipanti = [];
		this.immaginePartecipanti = null;
		this.tesseraSingola = null;
		this.parametriDichiarazione = new parametriDichiarazioni();
		this.prodottiCollegati = [];
		this.totali = null;
		this.complementari = [];
		this.ulterioriDati = [];
		this.inModifica = false;
	}
}

export class totaliCarrello{
	commissioni: number | null;
	totaleViaggio: number | null;
	totaleViaggioDaPagare: number | null;
	totaleViaggioImposte: number | null;
	totaleViaggioNetto: number | null;
	totaleViaggioPersona: number | null;
	totaleViaggioPersonaImposte: number | null;
	totaleMarkup: number | null;
	ritenutaAcconto: number | null;

	erroriTotaleCarrello: ErroriTotaleCarrello[];
}

export class ErroriTotaleCarrello {
	codice: string;
	descrizione: string;
	data: object | null;
}