import { TextValueExtended } from '../TextValueItem';
export class AgenzieRegistrazioneItem {
	/**
	 *
	 */
	constructor() {
		this.itemID = 0;
		this.ragioneSociale = null;
		this.denominazione = null;
		this.pIva = null;
		this.cf = null;
		this.autorizzazione = null;
		this.autorizzazioneRegione = null;
		this.autorizzazioneData = null;
		this.adv = false;
		this.presenzaDipendenti = null;
		this.to = false;
		this.address = null;
		this.citta = null;
		this.cap = null;
		this.provincia = null;
		this.tel = null;
		this.fax = null;
		this.email = null;
		this.pec = null;
		this.addressLeg = null;
		this.cittaLeg = null;
		this.capLeg = null;
		this.provLeg = null;
		this.telLeg = null;
		this.faxLeg = null;
		this.nome = null;
		this.cognome = null;
		this.userName = null;
		this.emailTitolare = null;
		this.cellulare = null;
		this.data = null;
		this.userNome1 = null;
		this.userCognome1 = null;
		this.userName1 = null;
		this.userEmail1 = null;
		this.userNome2 = null;
		this.userCognome2 = null;
		this.userName2 = null;
		this.userEmail2 = null;
		this.userNome3 = null;
		this.userCognome3 = null;
		this.userName3 = null;
		this.userEmail3 = null;
		this.userNome4 = null;
		this.userCognome4 = null;
		this.userName4 = null;
		this.userEmail4 = null;
		this.userNome5 = null;
		this.userCognome5 = null;
		this.userName5 = null;
		this.userEmail5 = null;
		this.contNome = null;
		this.contCognome = null;
		this.contUserName = null;
		this.contEmail = null;
		this.associatoNetwork = null;
		this.visuraCamerale = null;
		this.accordoCollaborazione = null;
		this.privacyFile = null;
		this.dataRegistrazione = null;
		this.iPRegistrazione = null;
		this.privacy = null;
		this.docCartaIdentita = null;
		this.docCodiceFiscale = null;
		this.docEsercizioAttivita = null;
		this.docIscrizione = null;
		this.docQuestionario = null;
		this.firma_EnveloperId = null;
		this.firma_Data = null;
		this.distributoreId = null;
		this.titolareId = null;
		this.utente1Id = null;
		this.utente2Id = null;
		this.utente3Id = null;
		this.utente4Id = null;
		this.utente5Id = null;
		this.contabilitaId = null;
		this.registroImprese = null;
		this.userTitolare = [];
		this.userContabilita = [];
		this.userOperativi = [];

		this.tokenReCaptcha = "";
		this.caricatoDocumentoVisura = false;
		this.caricatoDocumentoIdentita = false;
		this.caricatoDocumentoCodiceFiscale = false;
		this.caricatoDocumentoAutorizzazione = false;

		this.showSedeLegale = false;

		this.ruoliTitolareForm = null;
		this.ruoliUser1Form = null;
		this.ruoliUser2Form = null;
		this.ruoliUser3Form = null;
		this.ruoliUser4Form = null;
		this.ruoliUser5Form = null;

		this.ruoliTitolare = [];
		this.ruoliUser1 = [];
		this.ruoliUser2 = [];
		this.ruoliUser3 = [];
		this.ruoliUser4 = [];
		this.ruoliUser5 = [];

		this.idAssociazione = null;
	}
	itemID: number;

	ragioneSociale: string;

	denominazione: string;

	pIva: string;

	cf: string;

	autorizzazione: string;

	autorizzazioneRegione: TextValueExtended;

	autorizzazioneData: Date | string | null;
	adv: boolean;
	to: boolean;
	presenzaDipendenti: number;

	address: string;

	citta: any;

	cap: string;

	provincia: any;

	tel: string;

	fax: string;

	email: string;

	pec: string;

	addressLeg: string;

	cittaLeg: any;

	capLeg: string;

	provLeg: any;

	telLeg: string;

	faxLeg: string;

	nome: string;

	cognome: string;

	userName: string;

	ruoliTitolareForm: any;

	emailTitolare: string;

	cellulare: string;
	data: Date | string | null;

	userNome1: string;

	userCognome1: string;

	userName1: string;

	userEmail1: string;

	ruoliUser1Form: any;

	userNome2: string;

	userCognome2: string;

	userName2: string;

	userEmail2: string;

	ruoliUser2Form: any;

	userNome3: string;

	userCognome3: string;

	userName3: string;

	userEmail3: string;

	ruoliUser3Form: any;

	userNome4: string;

	userCognome4: string;

	userName4: string;

	userEmail4: string;

	ruoliUser4Form: any;

	userNome5: string;

	userCognome5: string;

	userName5: string;

	userEmail5: string;

	ruoliUser5Form: any;

	contNome: string;

	contCognome: string;

	contUserName: string;

	contEmail: string;

	associatoNetwork: string;

	visuraCamerale: string;

	accordoCollaborazione: string;

	privacyFile: string;
	dataRegistrazione: Date | string | null;

	iPRegistrazione: string;
	privacy: boolean;

	docCartaIdentita: string;

	docCodiceFiscale: string;

	docEsercizioAttivita: string;

	docIscrizione: string;

	docQuestionario: string;

	firma_EnveloperId: string;
	firma_Data: Date | string | null;
	distributoreId: number | null;
	titolareId: number | null;
	utente1Id: number | null;
	utente2Id: number | null;
	utente3Id: number | null;
	utente4Id: number | null;
	utente5Id: number | null;
	contabilitaId: number | null;

	registroImprese: string;
	userTitolare: AgenziaUtenza[];
	userOperativi: AgenziaUtenza[];
	userContabilita: AgenziaUtenza[];

	tokenReCaptcha: string;

	caricatoDocumentoVisura: boolean;
	caricatoDocumentoIdentita: boolean;
	caricatoDocumentoCodiceFiscale: boolean;
	caricatoDocumentoAutorizzazione: boolean;

	showSedeLegale: boolean;

	autorizzazioneFile: File;
	cartaIdentitaFile: File;
	codiceFiscaleFile: File;
	visuraCameraleFile: File;

	ruoliTitolare: any[];
	ruoliUser1: any[];
	ruoliUser2: any[];
	ruoliUser3: any[];
	ruoliUser4: any[];
	ruoliUser5: any[];

	idAssociazione: number;
}
export class AgenziaUtenza {
	/**
	 *
	 */
	constructor() {
		this.userNome = null;
		this.userCognome = null;
		this.userName = null;
		this.userEmail = null;
		
	}

	userNome: string;
	userCognome: string;
	userName: string;
	userEmail: string;
}