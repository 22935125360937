// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import helpers from "@/helpers";
import { anagraficaPacchettiCategorie } from "@/models/carrello/categoria";
import { datiViaggio, totaliCarrello } from "@/models/carrello/datiViaggio";
import { CarrelloInfoProdotto } from "@/models/carrello/infoProdotto";
import { ParametriCarrelloItem } from "@/models/carrello/parametriCarrello";
import { parametriDichiarazioni } from "@/models/carrello/parametriDichiarazioni";
import { parametriPreventivatoreItem } from "@/models/carrello/parametriPreventivatore";
import { PartecipantiCarrello } from "@/models/carrello/partecipantiCarrello";
import { associaDistributoriPacchettiExtended } from "@/models/carrello/prodotti";
import { RigaCarrello, RigaCarrelloInfo } from "@/models/carrello/rigaCarrello";
import { TextValueItem, TextValueExtended } from "@/models/TextValueItem";
import api from "@/services/api";
import moment from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import TWEEN from "tween.js";
import { Partecipanti } from "@/models/carrello/partecipanti";
import { PartecipantiCarrelloResponse } from "@/models/carrello/partecipantiCarrelloResponse";
import { NOMEM } from "dns";

const _ENDPONT_TOTALI = api.endpoint.CARRELLO.TOTALI,
	_ENDPONT_COMPLEMENTARI = api.endpoint.CARRELLO.COMPLEMENTARI,
	_ENDPONT_EXCEL = api.endpoint.CARRELLO.EXCEL_PARTECIPANTI,
	_ENDPONT_FILE = api.endpoint.CARRELLO.FILE_PARTECIPANTI,
	_ENDPONT_CARRELLO = api.endpoint.CARRELLO.CARRELLO;

@Component({
	computed: {
		...mapState({
			prodottoSelezionato: (state: any) => state.prodottoSelezionato,
			prodottiCarrello: (state: any) => state.prodottiCarrello,
			datiViaggio: (state: any) => state.datiViaggio,
			width: (state: any) => state.width,
		})
	},

	methods: {
		...mapMutations(["setProdottiCarrello", "setDatiViaggio", "setProdottoSelezionato", "setProdotti", "setCategoria", "setItemsSelected"])
	}
})
export default class infoProdottoPage extends baseContextComponent {
	toggleInfoDestinazioniProdotto: boolean = false;
	toggleInfoDestinazioniProdottoModifica: boolean = false;

	titleInfoProdotto: string = "Informazioni sul Prodotto";

	width!: number;

	title: string = "Informazioni Viaggio";
	subtitle: string = "Compila i campi sottostanti";
	subtitleRed: string = "per scoprire la polizza adatta alle tue esigenze";

	destinazioni: TextValueExtended[] = [];
	nazionalita: TextValueExtended[] = [];
	//eta: TextValueItem[] = [{value: 1, text:"Entro i 70"},{value: 2, text:"Tra 70 e 75"},{value: 3, text:"Tra 75 e 90"},{value: 4, text:"Oltre i 90"}];
	eta: any[] = [];
	tipologieProdotti: TextValueItem[] = [];
	parametriItem: parametriPreventivatoreItem = null;

	selectedEta: any = null
	datiViaggio!: datiViaggio;
	prodottoSelezionato!: CarrelloInfoProdotto;
	prodottiCarrello!: RigaCarrelloInfo[];

	setProdottiCarrello!: (setProdottiCarrello: RigaCarrelloInfo[]) => void;
	setDatiViaggio!: (setDatiViaggio: datiViaggio) => void;
	setProdottoSelezionato!: (setProdottoSelezionato: CarrelloInfoProdotto) => void;
	setProdotti!: (setProdotti: associaDistributoriPacchettiExtended[]) => void;
	setCategoria!: (setCategoria: anagraficaPacchettiCategorie) => void;
	setItemsSelected!: (setItemsSelected: number[]) => void;

	note: string = null;
	rifPratica: string = null;

	aggiungiACarrelloClick: boolean = false;
	testoCalcolaPreventivo: string = "CALCOLA PREVENTIVO";

	inizioViaggio: Date = helpers.getToday();
	fineViaggio: Date = helpers.getToday();

	inizioViaggioMin: Date = helpers.getToday();
	inizioViaggioMax: Date = helpers.getMaxDate();

	fineViaggioMin: Date = helpers.getToday();
	fineViaggioMax: Date = helpers.getMaxDate();

	importoMin: number = 0;
	importoMax: number = 99999999999;

	postiMinimi: number = 1;
	postiMassimi: number = 999;

	inModifica: boolean = false;
	complementari: any[] = [];
	nonCalcolarePreventivo: boolean = false;

	helpers: any = helpers;

	showBoxTotali: boolean = true;
	rightBoxTotali: number = 6;

	numPartecipantiFocus: boolean = false;
	importoViaggioFocus: boolean = false;
	secondoImportoViaggioFocus: boolean = false;
	inizioViaggioFocus: boolean = false;
	fineViaggioFocus: boolean = false;
	numeroAlloggiViaggioFocus: boolean = false;

	tesseraSingola: boolean = false;
	hideTesseraSingola: boolean = false;

	codicePromozionale: string = "";
	showCodicePromozionale: boolean = true;
	showPacchettoDetail: boolean = false;
	cannotChangeParams: boolean = true;

	immaginePartecipantiGuid: string = null;
	filePartecipanti: any = null;
	imagePartecipanti: any = null;

	numeroAlloggiFocus: boolean = false;
	codicePromoFocus: boolean = false;
	secondoImportoFocus: boolean = false;

	get totaleComplessivo() {
		if (!this.datiViaggio.totali) return {};
		var totali = this.creaInfoTotali();
		return totali["tot"];
	}

	forzaSommaTotali() {
		// funzione che lancia il trigger del totaleComplessivo
		if (this.datiViaggio.totali && this.datiViaggio.totali.totaleViaggio) {
			var temp = this.datiViaggio.totali.totaleViaggio;
			this.datiViaggio.totali.totaleViaggio = 1;
			this.setDatiViaggio(this.datiViaggio);
			this.datiViaggio.totali.totaleViaggio = temp;
			this.setDatiViaggio(this.datiViaggio);
		}
	}

	onChangeComplementare() {
		this.calcolaPreventivo();
	}

	get textPremioPersona() {
		//return this.alloggiAbilitato() ? "Premio per Alloggio" : "Premio per Persona";
		return "Premio per Persona";
	}

	getTestoCarrello(tipoID) {
		
		var t = (this.prodottoSelezionato ? this.prodottoSelezionato.testiCarrello || [] : []).find(obj => {
			return obj.tipoID == tipoID;
		});
		if(tipoID === 6 && this.prodottoSelezionato.categoriaID === 4){
			return "Area di Residenza";
		}
		return t && t.testo ? t.testo : helpers.getTipoTestoCarrelloDefault(tipoID);
	}

	@Watch("complementari", { immediate: true })
	importoViaggioAbilitato() {
		if (!this.prodottoSelezionato) return true;

		if (helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.importoViaggioObbligatorio)) return true;

		if (this.secondoImportoAbilitato()) return true;
		if (!this.$refs.complementari) return false;
		// var compl = this.$refs.complementari as any;

		// for (var i = 0; i < compl.length; i++) {
		// 	if (compl[i].showImporto && compl[i].addComplemetare) return true;
		// }

		return false;
	}

	importoViaggioDisabled() {
		if (!this.secondoImportoAbilitato()) return false;

		if (this.parametriItem.secondoImporto && this.parametriItem.secondoImporto > 0) return true;
		return false;
	}

	secondoImportoDisabled() {
		if (!this.secondoImportoAbilitato()) return true;

		if (this.parametriItem.importoViaggio && this.parametriItem.importoViaggio > 0) return true;
		return false;
	}
	// get importoViaggioAbilitato() {
	// 	if (!this.prodottoSelezionato) return true;

	// 	if (helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.importoViaggioObbligatorio)) return true;

	// 	// if (!this.$refs.complementari) return false;
	// 	// var compl = this.$refs.complementari as any;

	// 	// for (var i = 0; i < compl.length; i++) {
	// 	// 	if (compl[i].showImporto && compl[i].addComplemetare) return true;
	// 	// }

	// 	return false;
	// }

	@Watch("complementari", { immediate: true })
	secondoImportoAbilitato() {
		if (!this.prodottoSelezionato) return true;

		if (helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.applicazioneImbarcazione)) return true;

		if (!this.$refs.complementari) return false;
		var compl = this.$refs.complementari as any;

		for (var i = 0; i < compl.length; i++) {
			if (compl[i].addComplemetare && helpers.getExtraInfoCarrello(compl[i].complementare.extraInfo, helpers.tipiExtraInfo.applicazioneImbarcazione)) return true;
		}

		return false;
	}

	@Watch("complementari", { immediate: true })
	alloggiAbilitato() {
		if (!this.prodottoSelezionato) return true;

		if (helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.applicazioneAlloggio)) return true;

		if (!this.$refs.complementari) return false;
		var compl = this.$refs.complementari as any;

		for (var i = 0; i < compl.length; i++) {
			if (compl[i].addComplemetare && helpers.getExtraInfoCarrello(compl[i].complementare.extraInfo, helpers.tipiExtraInfo.applicazioneAlloggio)) return true;
		}

		return false;
	}

	tendinaEtaAbilitata() {
		if (!this.prodottoSelezionato) return false;

		var etaMin = helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.limiteEtaMin),
			etaMax = helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.limiteEtaMax);

		etaMin = etaMin ? parseInt(etaMin.valore) || -1 : -1;
		etaMax = etaMax ? parseInt(etaMax.valore) || -1 : -1;
		
		return etaMax > 0;
	}

	created() {
		if (Object.keys(this.datiViaggio.parametri).length > 0) {
			this.parametriItem = this.datiViaggio.parametri;
		} else {
			this.parametriItem = new parametriPreventivatoreItem();
		}
	}

	beforeDestroy() {
		this.$root.$off("setInModifica");

		var divScroll = document.getElementsByClassName("app-content")[0];
		divScroll.removeEventListener("scroll", this.handleScroll);
	}
	async mounted()  {
		var self = this;
		this.$root.$on("setInModifica", inModifica => {
			if (self.datiViaggio.inModifica) {
				this.nonCalcolarePreventivo = true;
				this.getData();
				self.initModifica();
			}
		});
		if (this.datiViaggio.inModifica) this.nonCalcolarePreventivo = true;
		this.getData();

		if (this.alloggiAbilitato()) {
			this.parametriItem.numeroAlloggi = 1;
		}

		if (!this.prodottoSelezionato.mostraNazionalita && this.nazionalita.length) {
			var nIta = this.nazionalita[0];
			if (nIta) {
				this.parametriItem.nazionalita = nIta as any;
				this.updateParametri(nIta);
			}
		}

		if (this.datiViaggio.inModifica) this.initModifica();
		else {
			// if( this.secondoImportoAbilitato() && this.datiViaggio.parametri.importoViaggio &&
			// 	parseInt( this.datiViaggio.parametri.importoViaggio as any) > 0) {
			// 	this.datiViaggio.parametri.secondoImporto = parseInt( this.datiViaggio.parametri.importoViaggio as any)
			// 	this.datiViaggio.parametri.importoViaggio = 0;
			// 	this.parametriItem = this.datiViaggio.parametri;
			// }

			var etaMax = helpers.getExtraInfoCarrello(self.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.limiteEtaMax);
			etaMax = etaMax ? parseInt(etaMax.valore) || -1 : -1;

			if(etaMax > 0){
				await helpers.delay(1000);
			}
			if (this.validaDatiPreventivo(true)) {
				this.testoCalcolaPreventivo = "CONFERMA PREVENTIVO";
				this.calcolaPreventivo();
			}
			// se tutti i parametri sono definiti, imposta testo "Conferma Preventivo" e calcola preventivo
		}
		this.complementari = this.$refs.complementari as any[];

		var divScroll = document.getElementsByClassName("app-content")[0];

		divScroll.addEventListener("scroll", this.handleScroll);
	}

	handleScroll(event) {
		try {
			var divPolizzeContainer = document.getElementsByClassName("polizze-integrative-container")[0];
			if (divPolizzeContainer) {
				var elemRect = divPolizzeContainer.getBoundingClientRect();

				var elementToScroll = document.getElementsByClassName("totale-premio-container")[0];

				if (elemRect.top - 180 < 0) {
					elementToScroll.classList.add("box-riepilogo-totali-fixed");
				} else {
					elementToScroll.classList.remove("box-riepilogo-totali-fixed");
				}
			}
		} catch (ex) {
			console.log("errore nella gestione dello scroll del pannello dei totali");
			console.log(ex);
		}
	}

	initModifica() {
		this.testoCalcolaPreventivo = "CONFERMA";

		this.parametriItem = this.datiViaggio.parametri;

		Vue.set(this.parametriItem, "partecipanti", []);
		for (var i = 0; i < this.datiViaggio.partecipanti.length; i++) {
			var np = this.datiViaggio.partecipanti[i];
			this.parametriItem["partecipanti"].push({
				CodiceFiscale: np.CodiceFiscale,
				Cognome: np.Cognome,
				DataNascita: np.DataNascita,
				Nome: np.Nome
			} as PartecipantiCarrello);
		}

		var dest = this.datiViaggio.parametri.destinazioneStato;
		if (typeof dest == "number") this.datiViaggio.parametri.destinazioneStato = this.destinazioni.find(obj => obj.value == (dest as any)) as any;

		var naz = this.datiViaggio.parametri.nazionalita;
		if (typeof naz == "number") this.datiViaggio.parametri.nazionalita = this.nazionalita.find(obj => obj.value == naz) as any;

		this.$root.$emit("updatePartecipanti", true);
		this.$root.$emit("updateUlterioriDati", true);
		this.$root.$emit("updateDichiarazioni", true);

		this.note = this.datiViaggio.note || "";
		this.rifPratica = this.datiViaggio.riferimentoPratica || "";

		this.setDatiViaggio(this.datiViaggio);
		var msTimeout = 1500;
		var etaMax = helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.limiteEtaMax);
		etaMax = etaMax ? parseInt(etaMax.valore) || -1 : -1;
		if (etaMax > 0) 
			msTimeout = 3000;
		setTimeout(() => {
			this.nonCalcolarePreventivo = false;
			this.calcolaPreventivo();
		}, msTimeout);
	}

	getData() {
		var self = this;

		self.initDate();
		self.updateNumeroPartecipanti();

		self.destinazioni = this.prodottoSelezionato.destinazioni.map(function(obj) {
			var a = new TextValueExtended();
			//a.code = obj.alphaCode3;
			a.value = obj.code;
			a.text = obj.nazione;
			return a;
		});

		self.nazionalita = this.prodottoSelezionato.nazionalita.map(function(obj) {
			var a = new TextValueExtended();
			a.value = obj.codice;
			a.text = obj.nazione;
			return a;
		});

		api.getDatiDropDown(api.endpoint.DROPDOWN.TIPOLOGIE_PRODOTTI).then(res => {
			self.tipologieProdotti = res;
		});
		
		api.getDatiDropDown(api.endpoint.DROPDOWN.ETA_COMPLETO).then(res => {
			
			self.eta = res as any;
			var etaMin = helpers.getExtraInfoCarrello(self.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.limiteEtaMin),
			etaMax = helpers.getExtraInfoCarrello(self.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.limiteEtaMax);

			etaMin = etaMin ? parseInt(etaMin.valore) || -1 : -1;
			etaMax = etaMax ? parseInt(etaMax.valore) || -1 : -1;

			if (etaMax > 0) {
				
				var currEta = null;
				for(var i = 0; i < self.eta.length; i++){
					if( etaMax >= ( self.eta[i].etaMassima || 999 ) )
						currEta = self.eta[i];
				}
				self.parametriItem.etaRange = currEta;
				self.updateParametri(true);
			}
		});
	}

	onRemoveCodicePromo() {
		this.parametriItem.codicePromo = "";
		this.updateParametri(false);
	}

	updateParametri(nonCalcolarePreventivo) {
		this.datiViaggio.parametri = this.parametriItem;
		this.setDatiViaggio(this.datiViaggio);
		if (this.datiViaggio.totali && !nonCalcolarePreventivo)
			// se ho totali significa che ho già chiamato preventivo altre volte -> al change di ogni campo richiamo preventivo
			this.calcolaPreventivo();
	}

	updateParametriNoCalcolo(nonCalcolarePreventivo) {
		this.datiViaggio.parametri = this.parametriItem;
		this.setDatiViaggio(this.datiViaggio);
	}

	updatePartecipantiElenco() {
		this.datiViaggio.partecipanti = this.parametriItem.partecipanti;
		this.setDatiViaggio(this.datiViaggio);
	}

	cancellaTuttiPartecipanti() {
		Vue.set(this.parametriItem, "partecipanti", []);
		for (var i = 0; i < this.parametriItem.numeroPartecipanti; i++) {
			this.parametriItem.partecipanti.push(new PartecipantiCarrello());
		}

		this.datiViaggio.partecipanti = this.parametriItem.partecipanti;
	}

	impostaSoloCapogruppo() {
		Vue.set(this.parametriItem, "partecipanti", []);
		this.parametriItem.partecipanti.push(new PartecipantiCarrello());
		//this.datiViaggio.partecipanti = this.parametriItem.partecipanti;
	}

	reimpostaPartecipantiDaCapogruppo() {
		for (var i = 1; i < this.parametriItem.numeroPartecipanti; i++) {
			this.parametriItem.partecipanti.push(new PartecipantiCarrello());
		}

		this.datiViaggio.partecipanti = this.parametriItem.partecipanti;
	}

	onInputFileValue(value: File) {
		var self = this;
		this.filePartecipanti = value;

		if (!value) return;

		var formData = new FormData();
		formData.append("filePartecipanti", value);

		api.postFormData(_ENDPONT_EXCEL, formData).then(res => {
			if (!res) return;

			var dati = res.data as PartecipantiCarrelloResponse[],
				currData = self.parametriItem.partecipanti,
				nuoviDati = [] as PartecipantiCarrello[],
				j = 0;

			if (!dati || !dati.length) {
				var msg = "Il file caricato non contiene partecipanti. Verifica e carica nuovamente il file";
				this.$bvToast.toast(msg, { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
				return;
			}

			for (var i = 0; i < currData.length; i++) {
				if (currData[i].Nome && currData[i].Cognome) {
					nuoviDati.push(helpers.cloneObject(currData[i]));
					nuoviDati[j].ItemID = ++j;
				}
			}

			var currLength = nuoviDati.length + 1;
			for (var i = 0; i < dati.length; i++) {
				var obj = dati[i];
				nuoviDati.push({ ItemID: currLength + i, Cognome: obj.cognome, Nome: obj.nome, CodiceFiscale: obj.codiceFiscale, DataNascita: obj.dataNascita });
			}

			self.parametriItem.partecipanti = nuoviDati;

			Vue.set(self.parametriItem, "partecipanti", []);
			for (var i = 0; i < nuoviDati.length; i++) {
				var np = nuoviDati[i];
				self.parametriItem.partecipanti.push({
					CodiceFiscale: np.CodiceFiscale,
					Cognome: np.Cognome,
					DataNascita: np.DataNascita,
					Nome: np.Nome
				} as PartecipantiCarrello);
			}

			self.datiViaggio.partecipanti = self.parametriItem.partecipanti;

			self.setNumeroPartecipanti(nuoviDati.length);

			self.updateParametri(false);
		});

		setTimeout(function() {
			self.filePartecipanti = null;
		}, 3000);
	}

	updateImmaginePartecipanti() {
		this.datiViaggio.immaginePartecipanti = this.immaginePartecipantiGuid;
		if (this.datiViaggio.immaginePartecipanti) {
			this.hideTesseraSingola = true;
			this.tesseraSingola = false;
			this.datiViaggio.tesseraSingola = false;
		} else this.hideTesseraSingola = false;
		this.setDatiViaggio(this.datiViaggio);
	}

	onInputImageValue(value: File) {
		var self = this;

		if (!value) return;

		var formData = new FormData();
		formData.append("imgPartecipanti", value);

		api.postFormData(_ENDPONT_FILE, formData).then(res => {
			if (!res || !res.data) return;

			self.imagePartecipanti = value;
			self.immaginePartecipantiGuid = res.data;
			self.updateImmaginePartecipanti();

			/// DOPO AVER FATTO UPLOAD AZZERO TUTTI I VALORI
			self.cancellaTuttiPartecipanti();
			self.impostaSoloCapogruppo();

			setTimeout(function() {
				self.imagePartecipanti = null;
			}, 3000);
		});
	}

	onRimuoviFile() {
		var self = this;

		api.delete(`${_ENDPONT_FILE}`, this.immaginePartecipantiGuid).then(res => {
			if (!res || !res.data) return;

			self.immaginePartecipantiGuid = null;
			self.immaginePartecipantiGuid = "";
			self.updateImmaginePartecipanti();
			self.reimpostaPartecipantiDaCapogruppo();
		});
	}

	updateTesseraSingola() {
		this.datiViaggio.tesseraSingola = this.tesseraSingola;
		this.setDatiViaggio(this.datiViaggio);
	}

	updateDestinazione(){
		this.updateParametri(false);
	}

	updateImportoViaggio() {
		if (!this.importoViaggioAbilitato()) {
			this.parametriItem.importoViaggio = 0;
			this.updateParametri(false);
			return;
		}
		var val = parseFloat(this.parametriItem.importoViaggio as any),
			impMin = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "ImportoMinimo")) || 0,
			impMax = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "ImportoMassimo")) || null;
		if (val < 0) val = 0;
		if (impMin && val < impMin) {
			//var msg = "L'importo impostato e' inferiore al minimo di " + kendo.toString(impMin, "c");
			var msg = "L'importo viaggio inserito e' inferiore al minimo consentito di " + kendo.toString(impMin, "c") + ". Inserisci un importo maggiore";
			this.$bvToast.toast(msg, { variant: "warning", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			val = impMin;
		}
		if (impMax && val > impMax) {
			//var msg = "L'importo impostato supera il massimo di " + kendo.toString(impMax, "c");
			var msg = "L'importo viaggio supera il massimo consentito di " + kendo.toString(impMin, "c") + ". Inserisci un importo minore";
			
			this.$bvToast.toast(msg, { variant: "warning", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			//val = impMax;
			val = null;
		}
		this.parametriItem.importoViaggio = val;
		this.updateParametri(false);
	}

	updateSecondoImporto() {
		if (!this.secondoImportoAbilitato()) {
			this.updateParametri(false);
			return;
		}
		var val = parseFloat(this.parametriItem.secondoImporto as any),
			impMin = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "ImportoMinimo")) || 0,
			impMax = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "ImportoMassimo")) || null;
		if (val < 0) val = 0;
		if (impMin && val < impMin) {
			var msg = "L'importo impostato e' inferiore al minimo di " + kendo.toString(impMin, "c");
			this.$bvToast.toast(msg, { variant: "warning", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			val = impMin;
		}
		if (impMax && val > impMax) {
			var msg = "L'importo impostato supera il massimo di " + kendo.toString(impMax, "c");
			this.$bvToast.toast(msg, { variant: "warning", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			//val = impMax;
			val = null;
		}
		this.parametriItem.secondoImporto = val;
		this.updateParametri(false);
	}

	setNumeroPartecipanti(numero) {
		this.parametriItem.numeroPartecipanti = numero;
		this.updateNumeroPartecipantiNoResetNoCalcolo();
	}

	updateNumeroPartecipanti() {
		var val = parseInt(this.parametriItem.numeroPartecipanti as any),
			postiMinimi = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "PostiMinimi")) || 1,
			postiMassimi = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "PostiMassimi")) || null;

		if (val < 0 || isNaN(val)) val = 0;
		if (postiMinimi && val < postiMinimi) {
			val = postiMinimi;
		}
		if (postiMassimi && val > postiMassimi) {
			var msg = "Il numero di partecipanti supera il massimo di " + kendo.toString(postiMassimi, "n0");
			this.$bvToast.toast(msg, { variant: "warning", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			val = postiMassimi;
		}

		Vue.set(this.parametriItem, "numeroPartecipanti", val);
		Vue.set(this.parametriItem, "partecipanti", []);
		for (var i = 0; i < this.parametriItem.numeroPartecipanti; i++) {
			this.parametriItem.partecipanti.push(new PartecipantiCarrello());
		}

		this.updateParametri(false);

		this.$root.$emit("changeNumeroPartecipanti");
	}

	updateNumeroPartecipantiNoCalcolo() {
		var val = parseInt(this.parametriItem.numeroPartecipanti as any),
			postiMinimi = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "PostiMinimi")) || 1,
			postiMassimi = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "PostiMassimi")) || null;

		if (val < 0 || isNaN(val)) val = 0;
		if (postiMinimi && val < postiMinimi) {
			val = postiMinimi;
		}
		if (postiMassimi && val > postiMassimi) {
			var msg = "Il numero di partecipanti supera il massimo di " + kendo.toString(postiMassimi, "n0");
			this.$bvToast.toast(msg, { variant: "warning", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			val = postiMassimi;
		}

		Vue.set(this.parametriItem, "numeroPartecipanti", val);

		Vue.set(this.parametriItem, "partecipanti", []);
		for (var i = 0; i < this.parametriItem.numeroPartecipanti; i++) {
			this.parametriItem.partecipanti.push(new PartecipantiCarrello());
		}
		this.$root.$emit("changeNumeroPartecipanti");
	}

	updateNumeroPartecipantiNoResetNoCalcolo() {
		var val = parseInt(this.parametriItem.numeroPartecipanti as any),
			postiMinimi = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "PostiMinimi")) || 1,
			postiMassimi = parseInt(helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "PostiMassimi")) || null;

		if (val < 0 || isNaN(val)) val = 0;
		if (postiMinimi && val < postiMinimi) {
			val = postiMinimi;
		}
		if (postiMassimi && val > postiMassimi) {
			var msg = "Il numero di partecipanti supera il massimo di " + kendo.toString(postiMassimi, "n0");
			this.$bvToast.toast(msg, { variant: "warning", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			val = postiMassimi;
		}
	}

	updateNumeroAlloggi() {
		if (!this.alloggiAbilitato()) {
			this.updateParametri(false);
			return;
		}
		this.parametriItem.numeroAlloggi = 1; // numero alloggi fissato a 1 28/04/2023
		/*
		var val = parseInt(this.parametriItem.numeroAlloggi as any);

		if (val <= 0) {
			var msg = "Il numero degli alloggi deve essere minimo 1";
			this.$bvToast.toast(msg, { variant: "warning", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			val = 1;
		}
		this.parametriItem.numeroAlloggi = val;*/
		this.updateParametri(false);
	}

	async calcolaPreventivo() {
		if (this.nonCalcolarePreventivo) return;
		if (!this.validaDatiPreventivo(false)) return;

		var data = this.getDati();

		var tot = await api.chimataPost<totaliCarrello>(_ENDPONT_TOTALI, data);

		this.cannotChangeParams = true;
		var self = this;
		if (!tot || !tot.data || (tot.data.erroriTotaleCarrello && tot.data.erroriTotaleCarrello.length)) {
			if (tot.data.erroriTotaleCarrello && tot.data.erroriTotaleCarrello.length) {
				for (var i = 0; i < tot.data.erroriTotaleCarrello.length; i++) {
					var codice = tot.data.erroriTotaleCarrello[i].codice;
					var errMsg = tot.data.erroriTotaleCarrello[i].descrizione;

					if (codice) {
						errMsg = self.$i18n.t(codice).toString();
						if (tot.data.erroriTotaleCarrello[i].data && (tot.data.erroriTotaleCarrello[i].data as any).pacchetto) {
							errMsg += ": " + (tot.data.erroriTotaleCarrello[i].data as any).pacchetto;
						}
					}

					self.$bvToast.toast(errMsg, {
						variant: "danger",
						title: self.$i18n.t("msg.erroreGenerico").toString(),
						solid: true
					});
				}
			} else {
				alert("I dati inseriti non sono compatibili con la polizza selezionata. Per assistenza, contatta Webins");
			}
		}

		var complementari = await api.chimataPost<CarrelloInfoProdotto[]>(_ENDPONT_COMPLEMENTARI, data).then(async res => {
			await self.calcolaTotaliComplementari();

			return res;
		});

		/*this.datiViaggio = helpers.cloneObject(data );
		this.datiViaggio.nazionalita = (this.parametri.nazionalita as any ).text as string|| '';
		this.datiViaggio.destinazione = (this.parametri.destinazione as any ).text as string|| '';*/
		if (complementari && complementari.data && complementari.data.length) {
			complementari.data = complementari.data.sort((a, b) => {
				return a.tipo > b.tipo ? 1 : -1;
			});

			for (var i = 0; i < complementari.data.length; i++) {
				if (complementari.data[i].tipo === 1) {
					(complementari.data[i] as any).showMsgUlteriore = true;
					break;
				}
			}
		}

		this.datiViaggio.complementari = complementari.data || [];
		this.datiViaggio.totali = tot.data;
		this.setDatiViaggio(this.datiViaggio);

		//this.calcolaTotaliComplementari(tot.data);
		//this.expandBoxTotali();
	}

	async calcolaTotaliComplementari() {
		//setTimeout(async () => {
		this.complementari = this.$refs.complementari as any[];
		var compl = (this.$refs.complementari as any) || [];

		for (var i = 0; i < compl.length; i++) {
			if (compl[i].addComplemetare) await compl[i].calcolaTotali();
		}
		this.setDatiViaggio(this.datiViaggio);
		//}, 1000);
	}

	validaDatiPreventivo(nonMostrareToast): boolean {
		var dati = this.getDati(),
			err = [];

		if (!dati.inizioViaggio) err.push("Data inizio viaggio: inserisci la data di inizio del viaggio");

		if (!dati.fineViaggio) err.push("Data fine viaggio: inserisci la data di fine del viaggio");

		//if (!dati.statoID) err.push("Destinazione richiesta");

		if (!dati.statoID) {
			var testoDest = this.getTestoCarrello(helpers.tipiTestoCarrello.Destinazione);
			if (testoDest.toLowerCase().includes("massimale")) err.push("Massimale: seleziona il massimale del tuo viaggio.");
			else err.push(testoDest + ": seleziona la " + testoDest + " del tuo viaggio.");
		}

		if (!dati.nazionalitaID) err.push(this.getTestoCarrello(helpers.tipiTestoCarrello.NazionePartecipanti) + ": seleziona la " + this.getTestoCarrello(helpers.tipiTestoCarrello.NazionePartecipanti) + " del tuo viaggio.");

		if (this.importoViaggioAbilitato() && !this.secondoImportoAbilitato() && parseFloat(dati.importoViaggio as any) < 1) err.push("Importo Viaggio: inserisci l'importo del viaggio");

		if (this.secondoImportoAbilitato() && parseFloat(dati.secondoImporto as any) < 1 && parseFloat(dati.importoViaggio as any) < 1) err.push("Importo Viaggio: inserisci l'importo del viaggio.");

		if (this.alloggiAbilitato() && parseInt(dati.numeroAlloggi as any) < 1) err.push("Numero Alloggi: inserisci il numero di alloggi per il viaggio.");

		if (!dati.numeroPartecipanti) err.push("Partecipanti: inserisci il numero di partecipanti al viaggio");

		var etaMax = helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.limiteEtaMax);
		etaMax = etaMax ? parseInt(etaMax.valore) || -1 : -1;

		if (etaMax > 0 && (!this.datiViaggio || !this.datiViaggio.parametri.etaRange || (this.datiViaggio.parametri.etaRange.etaMassima || 999) > etaMax)) {
			err.push("Età non valida: la polizza è disponibile per un'età massima di " + etaMax + " anni");
		}


		if (!nonMostrareToast) {
			for (var i = 0; i < err.length; i++) {
				this.$bvToast.toast(err[i], { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			}
		}

		return err.length == 0;
	}

	validaDati() {
		var err = this.validaElencoPartecipanti() || [];

		if (!this.datiViaggio.parametriDichiarazione.checkPolizza) err.push("Assicurati di aver letto e accettato le condizioni di polizza.");

		if (!this.datiViaggio.parametriDichiarazione.checkPrivacy) err.push("Assicurati di aver letto e accettato l'informativa sulla privacy.");

		if (!this.datiViaggio.parametriDichiarazione.adeguatezza) err.push("Assicurati di aver letto e confermato le dichiarazioni di adeguatezza.");

		var compl = (this.$refs.complementari as any[]) || [],
			complOK = true;

		for (var i = 0; i < compl.length; i++) {
			if (compl[i].addComplemetare && !compl[i].validaDati()) complOK = false;
		}

		if (this.$refs.grigliaulterioriDati) {
			err = err.concat((this.$refs.grigliaulterioriDati as any).validaDati());
		}

		for (var i = 0; i < err.length; i++) {
			this.$bvToast.toast(err[i], { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
		}

		return err.length == 0 && complOK;
	}

	validaElencoPartecipanti() {
		var partecipanti = this.datiViaggio.partecipanti || [],
			err = [];

		if (!partecipanti.length) {
			err.push("Partecipanti: inserisci il numero di partecipanti al viaggio");
		}

		var numPartecipanti = this.getDati().numeroPartecipanti || 0;

		if (partecipanti.length && partecipanti.length !== numPartecipanti) {
			err.push("Partecipanti: Il numero nella lista non corrisponde al numero indicato nel campo corrispondente");
		}

		for (var i = 0; i < partecipanti.length; i++) {
			var p = partecipanti[i];
			p.Nome = p.Nome ? p.Nome.trim() : "";
			p.Cognome = p.Cognome ? p.Cognome.trim() : "";
		}

		var dati = partecipanti,
			imgPartecipanti = this.datiViaggio.immaginePartecipanti,
			txtPartecipanti = imgPartecipanti ? "il capogruppo" : "tutti i partecipanti",
			regexPartec = new RegExp(/^[a-zA-Z\- 'òàùéèì']+$/);

		for (let i = 0; i < dati.length; i++) {
			var p = dati[i];
			if (!p.Cognome) {
				//err.push("Cognome richiesto per " + txtPartecipanti);
				if(imgPartecipanti) err.push("Inserisci il cognome del capogruppo");
				else err.push("Partecipanti: inserisci il cognome per ciascun partecipante al viaggio");

				
				break;
			}

			if (p.CodiceFiscale && p.CodiceFiscale.length > 16) {
				//err.push("Il codice fiscale del passeggero " + (p.Nome || "") + " " + (p.Cognome || "") + " ha piu' di 16 caratteri. Correggerlo");
				err.push("Codice fiscale inserito per " + (p.Nome || "") + " " + (p.Cognome || "") + " contiene più di 16 caratteri.");
				break;
			}

			if ((p.Cognome && !regexPartec.test(p.Cognome)) || (p.Nome && !regexPartec.test(p.Nome))) {
				//err.push("Per nome e cognome sono ammessi solo caratteri alfabetici. Correggere il partecipante " + (p.Nome || "") + " " + (p.Cognome || ""));
				err.push("Partecipante: sono ammessi solo caratteri alfabetici. Correggere il partecipante " + (p.Nome || "") + " " + (p.Cognome || ""));
				break;
			}

			if (imgPartecipanti)
				// se img partecipanti e' caricata, controllo solo il capogruppo
				break;
		}

		var nascitaRequired = helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.dataNascitaObbligatorio),
			codFiscaleRequired = helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.codFiscaleObbligatorio),
			etaMin = helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.limiteEtaMin),
			etaMax = helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.limiteEtaMax);

		etaMin = etaMin ? parseInt(etaMin.valore) || -1 : -1;
		etaMax = etaMax ? parseInt(etaMax.valore) || -1 : -1;

		if (nascitaRequired) {
			for (var i = 0; i < dati.length; i++) {
				var p = dati[i];
				if (!p.DataNascita) {
					//err.push("Data di Nascita richiesta per " + txtPartecipanti);
					err.push("Data di Nascita: inserisci la data di nascita per " + ( p.Nome || "" ) + " " + (p.Cognome || "") );
					break;
				}
				if (imgPartecipanti)
					// se img partecipanti e' caricata, controllo solo il capogruppo
					break;
			}
		}

		if (codFiscaleRequired) {
			for (var i = 0; i < dati.length; i++) {
				var p = dati[i];
				if (!p.CodiceFiscale) {
					err.push("Codice Fiscale: inserisci il codice fiscale per " + ( p.Nome || "" ) + " " + (p.Cognome || "") );
					break;
				}
				if (imgPartecipanti)
					// se img partecipanti e' caricata, controllo solo il capogruppo
					break;
			}
		}

		// if (etaMin > 0 || etaMax > 0) {
		// 	for (var i = 0; i < dati.length; i++) {
		// 		var p = dati[i];
		// 		if (!p.DataNascita) {
		// 			err.push("Data di Nascita richiesta per " + txtPartecipanti);
		// 			break;
		// 		} else {
		// 			var dNascita = moment(p.DataNascita),
		// 				part = p.Nome + " " + p.Cognome,
		// 				age = moment().diff(dNascita, "years"); // ritorna età in anni
		// 			if (etaMin > 0 && age < etaMin) {
		// 				err.push("L'eta' del partecipante " + part + " e' inferiore all'eta' minima di " + etaMin + " anni");
		// 				break;
		// 			} else if (etaMax > 0 && age > etaMax) {
		// 				err.push("L'eta' del partecipante " + part + " e' superiore all'eta' massima di " + etaMax + " anni");
		// 				break;
		// 			}
		// 		}
		// 		if (imgPartecipanti)
		// 			// se img partecipanti e' caricata, controllo solo il capogruppo
		// 			break;
		// 	}
		// }

		return err;
	}

	getDati(): ParametriCarrelloItem {
		var par = helpers.cloneObject(this.datiViaggio.parametri) as parametriPreventivatoreItem;

		var data = new ParametriCarrelloItem();
		data.statoID = par.destinazioneStato ? (par.destinazioneStato as any).value || null : null;
		data.nazionalitaID = par.nazionalita ? ((par.nazionalita as any).value as number) || null : null;
		data.inizioViaggio = par.inizioViaggio;
		data.fineViaggio = par.fineViaggio;
		data.importoViaggio = par.importoViaggio || 0;
		data.secondoImporto = par.secondoImporto || 0;
		data.numeroPartecipanti = par.numeroPartecipanti;
		data.pacchettoID = this.prodottoSelezionato.pacchettoID;
		data.codicePromo = par.codicePromo;

		if (this.alloggiAbilitato()) data.numeroAlloggi = par.numeroAlloggi;

		// TERMINARE GESTIONE ALLOGGI -> CHECK ANCHE VALIDITA
		return data;
	}

	creaInfoTotali() {
		// funzione che crea oggetto con totali
		var totali = {};
		totali["prodotto"] = this.datiViaggio.totali;

		var compl = (this.$refs.complementari as any) || [];
		//var compl = ( this.complementari as any ) || [];

		for (var i = 0; i < compl.length; i++) {
			if (compl[i].addComplemetare) totali["compl" + compl[i].complementare.pacchettoID] = compl[i].totali;
		}

		var totGenerale = {
			commissioni: 0,
			totaleViaggio: 0,
			totaleViaggioDaPagare: 0,
			totaleViaggioImposte: 0,
			totaleViaggioPersona: 0,
			totaleViaggioPersonaImposte: 0,
			totaleMarkup: 0,
			scontoCoupon: null,
			ritenutaAcconto: 0
		};

		for (var n in totali) {
			if (!totali[n]) continue;
			totGenerale.commissioni += totali[n].commissioni;
			totGenerale.totaleViaggio += totali[n].totaleViaggio;
			totGenerale.totaleViaggioDaPagare += totali[n].totaleViaggioDaPagare;
			totGenerale.totaleViaggioImposte += totali[n].totaleViaggioImposte;
			totGenerale.totaleViaggioPersona += totali[n].totaleViaggioPersona;
			totGenerale.totaleViaggioPersonaImposte += totali[n].totaleViaggioPersonaImposte;
			totGenerale.totaleMarkup += totali[n].totaleMarkup;
			totGenerale.scontoCoupon += totali[n].scontoCoupon;
			totGenerale.ritenutaAcconto += (totali[n].ritenutaAcconto || 0 );
		}

		totali["tot"] = totGenerale;

		return totali;
	}

	checkMaxTotPersona() {
		var maxTot = 200;
		if (!this.datiViaggio.totali || this.datiViaggio.totali.totaleViaggioPersona <= maxTot) return 0;

		if (this.prodottoSelezionato.controlloIddEscluso) return 0;

		var msg =
			"Attenzione: importo polizza superiore ad \u20ac 200,00 ( limite imposto dalla normativa IDD ). Non e' possibile procedere all'adesione." +
			"Contattaci all'indirizzo assistenza@webins.it.";

		this.$bvToast.toast(msg, { variant: "warning", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });

		return 2;
	}

	initDate() {
		// var inizioViaggio = this.datiViaggio.inModifica ? this.datiViaggio.parametri.inizioViaggio : helpers.getToday(), // valore iniziale se in modifica
		// 	fineViaggio = this.datiViaggio.inModifica ? this.datiViaggio.parametri.fineViaggio :  helpers.getToday(), // valore iniziale se in modifica

		var inizioViaggio = this.datiViaggio.parametri.inizioViaggio, // valore iniziale se in modifica
			fineViaggio = this.datiViaggio.parametri.fineViaggio, // valore iniziale se in modifica
			offsetDataMinima = this.prodottoSelezionato.offsetDataMinima || 0, // num giorni di offset
			dataMinima = new Date(helpers.getToday().getTime() + offsetDataMinima * 24 * 60 * 60 * 1000); // num giorni di offset

		if (helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.limite30GG)) {
			dataMinima = new Date(helpers.getToday().getTime() + 30 * 24 * 60 * 60 * 1000);
		}

		var dataMinimaParametro = helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.dataMinima),
			dataMassimaParametro = helpers.getExtraInfoCarrello(this.prodottoSelezionato.extraInfo, helpers.tipiExtraInfo.dataMassima),
			dataMax = helpers.getMaxDate();

		if (dataMinimaParametro) dataMinima = moment(dataMinimaParametro.valore, "DD/MM/YYYY").toDate();

		if (dataMassimaParametro) dataMax = moment(dataMassimaParametro.valore, "DD/MM/YYYY").toDate();

		this.inizioViaggioMin = dataMinima;
		this.inizioViaggioMax = dataMax;

		this.parametriItem.inizioViaggio = inizioViaggio ? moment(inizioViaggio).toDate() : null; // ritorna domani

		/// DATA FINE

		this.parametriItem.fineViaggio = fineViaggio ? moment(fineViaggio).toDate() : null; // ritorna domani
		this.fineViaggioMin = dataMinima;
		this.fineViaggioMax = dataMax;

		if (this.parametriItem.inizioViaggio || this.parametriItem.fineViaggio) this.changeInizioViaggio();
	}

	changeInizioViaggio() {
		var valore = this.parametriItem.inizioViaggio;
		var dataMinima = this.inizioViaggioMin;

		var durataMinStr = helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "DurataMinima");
		var durataMin = isNaN(durataMinStr) == false ? parseInt(durataMinStr) : 0;
		var durataMaxStr = helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "DurataMassima");
		var durataMax = isNaN(durataMaxStr) == false ? parseInt(durataMaxStr) : 0;

		if (!valore || valore < dataMinima) {
			this.parametriItem.inizioViaggio = dataMinima;
			valore = dataMinima;
		}

		var daysToAdd = durataMin || 0;
		this.fineViaggioMin = helpers.addDaysToDate(valore, daysToAdd);

		if (durataMax && !isNaN(durataMax) && durataMax > 0)
			// durata max - 1 per escludere il primo giorno
			this.fineViaggioMax = helpers.addDaysToDate(valore, durataMax - 1);

		this.changeFineViaggio();

		//this.updateParametri(false);
	}

	changeFineViaggio() {
		var valore = this.parametriItem.fineViaggio,
			inizioDate = this.parametriItem.inizioViaggio,
			durataMinStr = helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "DurataMinima"),
			durataMaxStr = helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "DurataMassima");

		var durataMax = isNaN(durataMaxStr) == false ? parseInt(durataMaxStr) : 0;
		var durataMin = isNaN(durataMinStr) == false ? parseInt(durataMinStr) : 0;

		var dataMin = helpers.addDaysToDate(inizioDate, durataMin || 0);
		var dataMax = durataMax ? helpers.addDaysToDate(inizioDate, durataMax) : helpers.getMaxDate();

		if (dataMin > valore) {
			this.parametriItem.fineViaggio = dataMin;
		} else if (dataMax && valore > helpers.addDaysToDate(dataMax, -1)) {
			//Toast.show("Le date non rispettano la durata massima del viaggio", { type: "error" });
			this.parametriItem.fineViaggio = helpers.addDaysToDate(dataMax, -1);
		}

		//!that.caller.primoCalcolo && that.caller.onOkProdotto(true);
		this.updateParametri(false);
	}

	changeInizioViaggioNoCalcolo() {
		var valore = this.parametriItem.inizioViaggio;
		var dataMinima = this.inizioViaggioMin;

		var durataMinStr = helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "DurataMinima");
		var durataMin = isNaN(durataMinStr) == false ? parseInt(durataMinStr) : 0;
		var durataMaxStr = helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "DurataMassima");
		var durataMax = isNaN(durataMaxStr) == false ? parseInt(durataMaxStr) : 0;

		if (!valore || valore < dataMinima) {
			this.parametriItem.inizioViaggio = dataMinima;
			valore = dataMinima;
		}

		var daysToAdd = durataMin || 0;
		this.fineViaggioMin = helpers.addDaysToDate(valore, daysToAdd);

		if (durataMax && !isNaN(durataMax) && durataMax > 0)
			// durata max - 1 per escludere il primo giorno
			this.fineViaggioMax = helpers.addDaysToDate(valore, durataMax - 1);

		this.changeFineViaggioNoCalcolo();

		//this.updateParametri(false);
	}

	changeFineViaggioNoCalcolo() {
		var valore = this.parametriItem.fineViaggio,
			inizioDate = this.parametriItem.inizioViaggio,
			durataMinStr = helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "DurataMinima"),
			durataMaxStr = helpers.getParametroValidazioneCarrello(this.prodottoSelezionato["parametroViaggio"], "DurataMassima");

		var durataMax = isNaN(durataMaxStr) == false ? parseInt(durataMaxStr) : 0;
		var durataMin = isNaN(durataMinStr) == false ? parseInt(durataMinStr) : 0;

		var dataMin = helpers.addDaysToDate(inizioDate, durataMin || 0);
		var dataMax = durataMax ? helpers.addDaysToDate(inizioDate, durataMax) : helpers.getMaxDate();

		if (dataMin > valore) {
			this.parametriItem.fineViaggio = dataMin;
		} else if (dataMax && valore > helpers.addDaysToDate(dataMax, -1)) {
			//Toast.show("Le date non rispettano la durata massima del viaggio", { type: "error" });
			this.parametriItem.fineViaggio = helpers.addDaysToDate(dataMax, -1);
		}
	}

	async aggiungiAlCarrello() {
		var self = this;
		return new Promise(async function(resolve, reject) {
			if (self.aggiungiACarrelloClick || !self.validaDatiPreventivo(false) || !self.validaDati()) {
				reject("Validazione prodotto non riuscita");
				return;
			}

			self.aggiungiACarrelloClick = true;

			var data = self.buildDatiPerCarrello();

			var tot = await api.chimataPost<totaliCarrello>(_ENDPONT_TOTALI, data);
			self.datiViaggio.totali = tot.data;
			self.setDatiViaggio(self.datiViaggio);

			var infoTotali = self.creaInfoTotali();
			data.infoTotali = infoTotali ? btoa(JSON.stringify(infoTotali)) : null;

			var limiteSuperato = self.checkMaxTotPersona(),
				resCarrello = null;
			if (limiteSuperato === 1) {
				self.aggiungiACarrelloClick = false;
				reject();
				return;
				//self.aggiungiProdottoIDD(dati);  // DA COMPLETARE AGGIUNTA PRODOTTO IDD ( FORSE NON SERVE PIU' )
			} else if (limiteSuperato === 0) {
				resCarrello = await api.chimataPost(_ENDPONT_CARRELLO, data);
				var prodottiCarrello = await api.getCarrello();
				self.setProdottiCarrello(prodottiCarrello);

				// torna alle categorie
				// apri carrello ( forse )
				// svuota store ( parameriDichiarazioni, tesseraSingola, prodottoSelezionato, partecipanti, parametri )
				self.setDatiViaggio(new datiViaggio());
				self.setProdottoSelezionato(null);
				self.setCategoria(null);
				self.setProdotti([]);
				self.setItemsSelected([]);

				self.$router.push({ path: "carrelloCategorie" });

				self.$root.$emit("openCarrello");
			}

			self.aggiungiACarrelloClick = false;

			resolve(resCarrello);
		});
	}

	async modificaProdotto() {
		if (this.aggiungiACarrelloClick || !this.validaDatiPreventivo(false) || !this.validaDati()) return;

		this.aggiungiACarrelloClick = true;

		var data = this.buildDatiPerCarrello();

		data.itemID = this.datiViaggio.itemID; // COMPLETARE

		var tot = await api.chimataPost<totaliCarrello>(_ENDPONT_TOTALI, data);

		this.datiViaggio.totali = tot.data;
		this.setDatiViaggio(this.datiViaggio);

		var infoTotali = this.creaInfoTotali();
		data.infoTotali = infoTotali ? btoa(JSON.stringify(infoTotali)) : null;

		var limiteSuperato = this.checkMaxTotPersona();
		if (limiteSuperato === 1) {
			//this.aggiungiProdottoIDD(dati);  // DA COMPLETARE AGGIUNTA PRODOTTO IDD ( FORSE NON SERVE PIU' )
		} else if (limiteSuperato === 0) {
			var res = await api.chiamataPut(_ENDPONT_CARRELLO, data);
			var prodottiCarrello = await api.getCarrello();
			this.setProdottiCarrello(prodottiCarrello);

			// torna alle categorie
			// apri carrello ( forse )
			// svuota store ( parameriDichiarazioni, tesseraSingola, prodottoSelezionato, partecipanti, parametri )

			this.$router.push({ path: "carrelloCategorie" as string });

			this.setProdottoSelezionato(null);
			this.setCategoria(null);
			this.setProdotti([]);
			this.setItemsSelected([]);

			this.setDatiViaggio(new datiViaggio());
			this.$root.$emit("openCarrello");
		}

		this.aggiungiACarrelloClick = false;
	}

	async acquista1Click() {
		this.aggiungiAlCarrello()
			.then(res => {
				this.$root.$emit("acquista1Click");
			})
			.catch(err => {});
	}

	get destinazioneID(): number {
		return ((this.parametriItem.destinazioneStato as any) || {}).value || null;
	}
	get nazionalitaID(): number {
		return ((this.parametriItem.destinazioneStato as any) || {}).value || null;
	}
	buildDatiPerCarrello(): RigaCarrello {
		var par = helpers.cloneObject(this.datiViaggio.parametri) as parametriPreventivatoreItem;

		var data = new RigaCarrello();

		data.pacchettoID = this.prodottoSelezionato.pacchettoID;
		data.inizioViaggio = par.inizioViaggio;
		data.fineViaggio = par.fineViaggio;
		data.numeroPartecipanti = par.numeroPartecipanti;
		data.statoID = par.destinazioneStato ? ((par.destinazioneStato as any).value as number) || null : null;
		data.nazionalitaID = par.nazionalita ? ((par.nazionalita as any).value as number) || null : null;

		data.importoViaggio = this.importoViaggioAbilitato() ? par.importoViaggio || 0 : 0;
		data.secondoImporto = this.secondoImportoAbilitato() ? par.secondoImporto || 0 : 0; // DA GESTIRE POI
		data.numeroAlloggi = this.alloggiAbilitato() ? par.numeroAlloggi : 0; // DA GESTIRE POI

		data.note = this.note || "";
		data.riferimentoPratica = this.rifPratica || "";

		var part = this.datiViaggio.partecipanti || [];

		data.elencoPartecipanti = btoa(JSON.stringify(part));
		data.tesseraSingola = this.datiViaggio.tesseraSingola || false;
		data.imageFilePartecipanti = this.datiViaggio.immaginePartecipanti;

		data.codicePromo = par.codicePromo || "";

		data.adeguatezza = this.datiViaggio.parametriDichiarazione.adeguatezza || null;

		data.ulterioriDati = this.datiViaggio.ulterioriDati && this.datiViaggio.ulterioriDati.length ? btoa(JSON.stringify(this.datiViaggio.ulterioriDati)) : null;

		var pCollegati = JSON.stringify(this.datiViaggio.prodottiCollegati || []);
		data.prodottiCollegati = pCollegati;
		return data;
	}

	/*
	expandBoxTotali() {
		this.showBoxTotali = true;
		var box = this.$refs.box as any;
		box.classList.remove("animate__fadeOutRight");
		box.classList.add("animate__fadeInRight");
	}

	collapseBoxTotali() {
		var box = this.$refs.box as any;
		box.classList.remove("animate__fadeInRight");
		box.classList.add("animate__fadeOutRight");
		setTimeout(() => {
			this.showBoxTotali = false;
			box.classList.remove("animate__fadeOutRight");
		}, 400);
	}*/

	expandBoxTotali() {
		var self = this;
		this.showBoxTotali = true;
		var right = { x: -280 };
		var tween = new TWEEN.Tween(right)
			.to({ x: 6 }, 250)
			.onUpdate(function() {
				self.rightBoxTotali = this.x;
			})
			.start();

		var animate = time => {
			requestAnimationFrame(animate);
			TWEEN.update(time);
		};

		requestAnimationFrame(animate);
	}

	collapseBoxTotali() {
		var self = this;

		var right = { x: 6 };
		var tween = new TWEEN.Tween(right)
			.to({ x: -280 }, 250)
			.onUpdate(function() {
				self.rightBoxTotali = this.x;
			})
			.onComplete(function() {
				self.showBoxTotali = false;
			})
			.start();

		var animate = time => {
			requestAnimationFrame(animate);
			TWEEN.update(time);
		};

		requestAnimationFrame(animate);
	}

	scrollToTop() {
		var body = this.$el.parentElement.parentElement;

		var top = { x: body.scrollTop };
		new TWEEN.Tween(top)
			.to({ x: 0 }, 250)
			.onUpdate(function() {
				body.scrollTop = this.x;
			})
			.start();

		var animate = time => {
			requestAnimationFrame(animate);
			TWEEN.update(time);
		};

		requestAnimationFrame(animate);
	}

	onClickBoxTotali() {
		if (this.showBoxTotali) this.collapseBoxTotali();
		else this.expandBoxTotali();
	}

	onIndietro() {
		this.$router.push({ path: "carrelloCategorie" as string });
	}

	get isDescriptionNameOfProduct() {
		if (
			this.prodottoSelezionato &&
			this.prodottoSelezionato.nomeProdotto &&
			this.prodottoSelezionato.descrizioneSintetica &&
			this.prodottoSelezionato.nomeProdotto.toLowerCase() == this.prodottoSelezionato.descrizioneSintetica.toLowerCase()
		) {
			return true;
		}

		return false;
	}

	onKeyDownImporto(e) {		
		if( e.which === 190 || e.which === 110 ){
			e.preventDefault();
			e.stopPropagation();
			return false;
		}
	}

	async getTerminiECondizioni(prodotto) {
		var self = this;

		
		helpers.getTerminiECondizioni(this,prodotto.pacchettoID, prodotto.prodottoID);

		/*api.getWithAllDataResponse(`${api.endpoint.CARRELLO.TERMINI_E_CONDIZIONI}?pacchettoId=${prodotto.pacchettoID}&prodottoId=${prodotto.prodottoID}`, { responseType: "blob" })
			.then(res => {
				const blob = new Blob([res.data], { type: res.headers["content-type"] });

				if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
					(window.navigator as any).msSaveOrOpenBlob(blob);
				} else {
					var fileURL = URL.createObjectURL(blob);
					window.open(fileURL, "_blank");
				}
			})
			.catch(err => {
				self.$bvToast.toast(self.$i18n.t("errore.fileNotFound").toString(), {
					variant: "danger",
					title: self.$i18n.t("generico.errore").toString(),
					solid: true
				});
			});*/
	}
}
